import React from "react";
import "../styles.css";
import "./container.css";
import "./home.css";
import Header from "../header/header";
import Footer from "../footer/footer";
import Layout from "../layout/layout";
import shuttlecockImage from "../img/badminton-shuttlecock.png";
import pointageImage from "../img/Pointage_CLTO_Badminton.png";

const Container: React.FC = () => {
	const isConnected = true;
	return (
		<Layout isConnected={isConnected}>
			<div className='home-content'>
				<h4 className='home-title'>
					Bienvenue dans l'application de pointage des adhérents en version 1.0{" "}
					<b className='red'>BETA</b>
				</h4>
				<img src={pointageImage} className='shuttlecock-image' />
			</div>
		</Layout>
	);
};

const Home = () => {
	return (
		<div className='rootpage container'>
			<Header />
			<Container />
			<Footer />
		</div>
	);
};

export default Home;
