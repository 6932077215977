import axios from "axios";
import { plainToInstance } from "class-transformer";
import { validate } from "class-validator";
import { ICreneau } from "../interface/ICreneau";
import { IJoueur } from "../interface/IJoueur";
import { Creneau } from "../class/creneau";
import { Gymnase } from "../class/gymnase";
import { Joueur, JoueurInsert } from "../class/joueur";
import { Saisie, SaisieInsert, SaisieDetail } from "../class/saisie";
import { Pointage, PointageInsert } from "../class/pointage";
import { saisonActuelle, setSaisonActuelle } from "../config";
import { ISaisieDetail } from "../interface/ISaisieDetail";

// Définissez l'URL de base de votre API locale, utilisez une URL différente en production
//const BASE_URL = "http://localhost:8888/clto-admin/api/";
const BASE_URL = "https://www.gestion.cltobadminton.fr/api/";
//const BASE_URL = "http://192.168.1.130:8888/clto-admin/api/";

// Mettez à jour la saison récupérée depuis l'API
https: setSaisonActuelle(14);

// Fonction pour charger les gymnases depuis l'API
export const loadGymnases = async () => {
	try {
		const response = await axios.get(`${BASE_URL}gymnases/${saisonActuelle}`);
		console.trace("donnée de Axios des gymnases ", response.data);
		// Désérialisez les données JSON en une instance de Gymnase
		const gymnases: Gymnase[] = plainToInstance(Gymnase, response.data);

		// Validez l'objet résultant
		const errors = await validate(gymnases);

		if (errors.length > 0) {
			console.error("Erreurs de validation :", errors);
			throw new Error("Validation failed");
		}

		//console.trace("Données de gymnases désérialisées", gymnases);

		return gymnases;
	} catch (error) {
		// Gérez les erreurs ici
		throw error;
	}
};

// Fonction pour charger les créneaux depuis l'API
export const loadCreneaux = async () => {
	try {
		let response = await axios.get<ICreneau[]>(
			`${BASE_URL}creneaux/${saisonActuelle}`
		);

		//console.trace("donnée de Axios des creneaux ", response.data);
		// Désérialisez les données JSON en une instance de Gymnase
		const creneaux: Creneau[] = plainToInstance(Creneau, response.data);

		// Validez l'objet résultant
		const errors = await validate(creneaux);

		if (errors.length > 0) {
			console.error("Erreurs de validation :", errors);
			throw new Error("Validation failed");
		}

		return creneaux;
	} catch (error) {
		// Gérez les erreurs ici
		throw error;
	}
};

// Fonction pour charger les joueurs depuis l'API
export const loadJoueurs = async () => {
	try {
		const response = await axios.get<IJoueur[]>(
			`${BASE_URL}joueurs/${saisonActuelle}`
		);
		//console.trace("donnée de Axios des joueurs ", response.data);
		// Désérialisez les données JSON en une instance de Gymnase
		const joueurs: Joueur[] = plainToInstance(Joueur, response.data);

		// Validez l'objet résultant
		const errors = await validate(joueurs);

		if (errors.length > 0) {
			console.error("Erreurs de validation :", errors);
			throw new Error("Validation failed");
		}
		const joueursParNom = joueurs.sort(
			(a, b) => a.nom.localeCompare(b.nom) || a.prenom.localeCompare(b.prenom)
		);
		return joueursParNom;
	} catch (error) {
		// Gérez les erreurs ici
		throw error;
	}
};

// Fonction pour charger les joueurs depuis l'API
export const loadjoueurshabituelsbycreneau = async (creneauId: number) => {
	try {
		const response = await axios.get<IJoueur[]>(
			`${BASE_URL}joueurshabituelsbycreneau/${creneauId}`
		);
		//console.trace("donnée de Axios des joueurs par creneau ", response.data);
		// Désérialisez les données JSON en une instance de Gymnase
		const joueurs: IJoueur[] = plainToInstance(Joueur, response.data);

		// Validez l'objet résultant
		const errors = await validate(joueurs);

		if (errors.length > 0) {
			console.error("Erreurs de validation :", errors);
			throw new Error("Validation failed");
		}

		console.info("joueurs habituels d'un creneau:", creneauId, joueurs);

		return joueurs;
	} catch (error) {
		// Gérez les erreurs ici
		throw error;
	}
};

// Fonction pour charger les joueurs depuis l'API
export const loadJoueursBySaisie = async (saisieId: number) => {
	try {
		const response = await axios.get<IJoueur[]>(
			`${BASE_URL}joueurspointebysaisie/${saisieId}`
		);
		//console.trace("donnée de Axios des joueurs par creneau ", response.data);
		// Désérialisez les données JSON en une instance de Gymnase
		const joueurs: IJoueur[] = plainToInstance(Joueur, response.data);

		// Validez l'objet résultant
		const errors = await validate(joueurs);

		if (errors.length > 0) {
			console.error("Erreurs de validation :", errors);
			throw new Error("Validation failed");
		}

		console.info("joueurs pointé par saisie:", saisieId, joueurs);

		return joueurs;
	} catch (error) {
		// Gérez les erreurs ici
		throw error;
	}
};
// Fonction pour charger les saisies par créneau depuis l'API
export const findSaisie = async (creneauId: number, dateCreneau: String) => {
	try {
		const response = await axios.get(
			`${BASE_URL}saisie/existe/${creneauId}/${dateCreneau}`,
			{
				params: {
					limit: 1,
				},
			}
		);
		console.info(
			"adresse api/saisie/existe/ ",
			`${BASE_URL}saisie/existe/${creneauId}/${dateCreneau}`,
			" donnée de Axios des saisies ",
			response.data,
			" pour le creneau",
			creneauId
		);
		// Désérialisez les données JSON en une instance de Gymnase
		const saisies: Saisie[] = plainToInstance(Saisie, response.data);

		// Validez l'objet résultant
		const errors = await validate(saisies);

		if (errors.length > 0) {
			console.error("Erreurs de validation :", errors);
			throw new Error("Validation failed");
		}

		const saisie = saisies[0];

		console.info("saisies d'un creneau:", creneauId, saisie);

		return saisie;
	} catch (error) {
		// Gérez les erreurs ici
		throw error;
	}
};

// Fonction pour insérer un nouveau joueur
export const InsertPlayer = async (nouveauJoueur: Joueur): Promise<number> => {
	try {
		const nouveauJoueurInsert: JoueurInsert = {
			nom: nouveauJoueur.nom.toUpperCase(),
			prenom: nouveauJoueur.prenom.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
				letter.toUpperCase()
			),
			saison_id: saisonActuelle,
		};

		const response = await axios.post(
			`${BASE_URL}joueurs/insert/`,
			JSON.stringify(nouveauJoueurInsert)
		);
		return Number(response.data.id);
	} catch (error) {
		console.error(
			"Erreur lors de l`'insertion du joueur:",
			{
				nom: nouveauJoueur.nom,
				prenom: nouveauJoueur.prenom,
				saison: 12,
			},
			error
		);
		throw error;
	}
};

// Fonction pour insérer une nouvelle saisie
export const InsertSaisie = async (nouvelleSaisie: Saisie): Promise<number> => {
	const nouvelleSaisieInsert: SaisieInsert = new SaisieInsert(
		nouvelleSaisie.gymnase_id,
		nouvelleSaisie.creneau_id,
		nouvelleSaisie.date_saisie,
		nouvelleSaisie.saison_id
	);
	try {
		const response = await axios.post(
			`${BASE_URL}saisies/insert/`,
			JSON.stringify(nouvelleSaisieInsert)
		);
		return Number(response.data.id);
	} catch (error) {
		console.error(
			"Erreur lors de l`'insertion d'une saisie :",
			nouvelleSaisieInsert,
			error
		);
		throw error;
	}
};

// Fonction pour insérer de nouveaux pointages
export const InsertPointage = async (
	pointage: PointageInsert
): Promise<number> => {
	try {
		const response = await axios.post(
			`${BASE_URL}pointages/insert/`,
			JSON.stringify(pointage)
		);
		console.log("pointage crée : ", response.data);
		return Number(response.data.id);
	} catch (error) {
		console.error(
			"Erreur lors de l`'insertion des pointages :",
			pointage,
			error
		);
		throw error;
	}
};

export const UpdatePointage = async (pointage: Pointage): Promise<number> => {
	try {
		const response = await axios.post(
			`${BASE_URL}pointages/update/`,
			JSON.stringify(pointage)
		);
		return Number(response.data.id);
	} catch (error) {
		console.error(
			"Erreur lors de l`'insertion des pointages :",
			pointage,
			error
		);
		throw error;
	}
};

export const DeletePointageFromSaisie = async (
	saisieId: number
): Promise<boolean> => {
	try {
		const res = await axios.post(`${BASE_URL}pointages/delete/${saisieId}`);
		//if (res.status !=== 200)
		//return false;
		//else
		return true;
	} catch (error) {
		console.error("Erreur lors de la suppression:", error);
		return false;
	}
};

// Fonction pour préparer les pointages
export const preparePointages = async (
	saisieId: number,
	joueursPointe: Joueur[]
) => {
	try {
		let joueursPointageIds: number[] = [];
		console.info("Etat des joueurs à pointer", joueursPointe);
		// 0. Recuperation des joueurs I
		const joueursExistants = joueursPointe.filter(
			(jou) => jou.newadded !== true
		);
		console.info("liste des joueurs à pointer", joueursExistants);
		if (joueursExistants.length > 0) {
			const joueursExistantdsIds: number[] = joueursExistants.map(
				(jou) => jou.id
			);
			console.info("joueur pointés :", joueursExistantdsIds);
			joueursPointageIds = [...joueursExistantdsIds];
		}
		// 1. Insérer les nouveaux joueurs cochés "present=true"
		const joueursNouveaux = joueursPointe.filter(
			(jou) => jou.newadded === true
		);
		console.info("liste des joueurs joueurs a creer", joueursNouveaux);
		for (const joueur of joueursNouveaux) {
			const nouveauxId: number = await InsertPlayer(joueur);
			console.info("joueur crée :", nouveauxId);
			joueursPointageIds.push(nouveauxId);
		}

		// 3. préparer les pointages pour tous les jouersID
		console.info("pointage a réaliser :", joueursPointageIds);
		const pointageAInserer: PointageInsert[] = joueursPointageIds.map(
			(jouId) => ({
				saisie_id: saisieId,
				joueur_id: jouId,
				saison_id: saisonActuelle,
			})
		);
		return pointageAInserer;
	} catch (error) {
		console.error("Erreur lors de la prepaparation des pointages :", error);
		console.error("joueurs  : ", joueursPointe);
		throw error;
	}
};

// Fonction pour effectuer toutes les opérations de validation et d'insertion
export const ExecuteValidation = async (
	joueursPointe: Joueur[],
	nouvelleSaisie: Saisie
) => {
	let errorData: string;
	let isValid: boolean = false;
	let pointagesCrees: IJoueur[] = [];
	try {
		//Insérer la saisie
		console.info("tentative de création d une saisie.", nouvelleSaisie);
		const saisieId: number = await InsertSaisie(nouvelleSaisie);
		console.info("insertion reussie d une saisie :", saisieId);
		if (saisieId != null) {
			console.info(
				"preparation des pointages pour la liste de joueurs ",
				joueursPointe,
				"pour l'id saisie ",
				saisieId
			);
			const pointagesACreer: PointageInsert[] = await preparePointages(
				saisieId,
				joueursPointe
			);
			console.info("liste des pointages a creer ", pointagesACreer);
			//Insérer les pointages
			for (const pointage of pointagesACreer) {
				console.info("insertion d'un pointage ", pointage);
				const pointageId: number = await InsertPointage(pointage);
				console.info("insertion réussie d'un pointage ", pointageId);
			}
			pointagesCrees = await loadJoueursBySaisie(saisieId);
			console.info("liste pointage crees ", pointagesCrees);
			if (
				pointagesCrees !== undefined &&
				pointagesACreer.length === pointagesCrees.length
			) {
				isValid = true;
				console.info("Toutes les insertions sont terminées avec succès.");
			}
		}
	} catch (error: any) {
		errorData =
			"Erreur lors de la validation et de l'insertion :" + error.message;
		console.error(errorData);
		console.error("saisie : ", nouvelleSaisie);
	}
	return new Promise<boolean>((resolve, reject) => {
		if (isValid) {
			resolve(true);
		} else {
			reject(new Error(errorData));
		}
	});
};

export const ModifyValidation = async (
	joueursPointe: Joueur[],
	saisieExistante: Saisie,
	JoueursPointehasChanged: boolean
) => {
	let errorData: string;
	let isValid = false;
	let pointagesCrees;
	try {
		console.info("tentative de modification d une saisie.", saisieExistante);
		if (JoueursPointehasChanged) {
			const resultDeletePointage = await DeletePointageFromSaisie(
				saisieExistante.id
			);
			if (resultDeletePointage === true) {
				console.info(
					"preparation des pointages pour la liste de joueurs ",
					joueursPointe,
					"pour l'id saisie ",
					saisieExistante.id
				);
				const pointagesACreer: PointageInsert[] = await preparePointages(
					saisieExistante.id,
					joueursPointe
				);

				// 4. Insérer les pointages
				for (const pointage of pointagesACreer) {
					console.info("modification d'un pointage ", pointage);
					const pointageId: number = await InsertPointage(pointage);
					console.info("modification réussie d'un pointage ", pointageId);
				}
				pointagesCrees = await loadJoueursBySaisie(saisieExistante.id);
				console.info("liste pointage crees ", pointagesCrees);

				console.info(
					"pointage a creer:  ",
					pointagesACreer.length,
					"pointage crées: ",
					pointagesCrees.length
				);
				if (
					pointagesCrees !== undefined &&
					pointagesACreer.length === pointagesCrees.length
				) {
					isValid = true;
					console.info("Toutes les modifications sont terminées avec succès.");
				}
			}
		} else {
			isValid = true;
		}
	} catch (error: any) {
		errorData = "Erreur lors de la modification :" + error.message;
		console.error(errorData);
		console.error("saisie : ", saisieExistante);
	}
	return new Promise<boolean>((resolve, reject) => {
		if (isValid) {
			resolve(true);
		} else {
			reject(new Error(errorData));
		}
	});
};

// Fonction pour charger les saisies par créneau depuis l'API
export const loadSaisiesDetail = async () => {
	try {
		const response = await axios.get<ISaisieDetail[]>(
			`${BASE_URL}saisiesdetail/${saisonActuelle}`
		);
		console.info(
			"adresse api/saisiesdetail/${saisonActuelle}",
			`${BASE_URL}saisiesdetail/${saisonActuelle}`,
			" donnée de Axios des saisies ",
			response.data
		);
		// Désérialisez les données JSON en une instance de Gymnase
		const saisies: ISaisieDetail[] = plainToInstance(
			SaisieDetail,
			response.data
		);

		// Validez l'objet résultant
		const errors = await validate(saisies);

		if (errors.length > 0) {
			console.error("Erreurs de validation :", errors);
			throw new Error("Validation failed");
		}

		console.info("saisies de la saison :", saisies);

		return saisies;
	} catch (error) {
		// Gérez les erreurs ici
		throw error;
	}
};
