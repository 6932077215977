export class Joueur {
    constructor(
        public id: number,
        public nom: string,
        public prenom: string,
        public saison_id : number,
        public licence: boolean,
        public nb_creneau: number,
        public present: boolean,
        public newadded: boolean
    ) {}
}

export class JoueurInsert {
    constructor(
        public nom: string,
        public prenom: string,
        public saison_id : number
    ) {}
}